.margin-item {
    font-size: 1.2rem;
    font-weight: bold;
}

.margin-item-yellow {
    color: #FAAD14;
}

.margin-item-green {
    color: #27ff0e;
}

.margin-item-red {
    color: #ff0000;
}

.margin-item-blue {
    color: #1890ff;
}

.margin-right-small{
    margin-right: 1.5rem;
}