.status {
    font-size: 1.2rem;
}

.status-green {
    color:#52C41A;
}

.status-grey {
    color: #D9D9D9;
}

.status-blue {
    color: #1890FF;
}

.status-red {
    color: #FF0000;
}

.status-brown {
    color: #793434;
}

.status-greener {
    color: #00FF33;
}

.status-yellow {
    color: #FFFF33;
}