.forbiddenPageTitle {
    font-size: 3rem;
    font-weight: 700;
    padding-top: 9rem;
    margin-bottom: 1rem;
    text-align: center;
}

.forbiddenPageBody{
    padding-top: 1rem;
    padding-bottom: 10rem;
    text-align: center;
}


.stop-sign{
    color: red;
    text-align: center;
    font-size: 3rem;
    padding-right: 1rem;
}