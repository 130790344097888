#side {
    background-color: #FFF;
    height: 100vh;
};

.menu-wrapper{
    min-height: 100vh;
}

.trigger-menu {
    text-align: center;
    padding-left: 1.2rem;
}

.trigger-wrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-bottom: 0.5rem;
}