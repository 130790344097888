.landing-wrapper{
  height: 20rem;
  position: relative
}

.landing {
  padding-top: 15%;
  margin: 0;
  font-size: 10rem;
  color: #40A9FF;
  text-align: center;
  height: 81vh;
}
