.notFoundPageTitle {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}

.image {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-height: 80vh;
    animation: rotate 240s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}