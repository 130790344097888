#header {
  background-color: #000;
  padding: 0;
}

.app-menu{
  padding-left: 1.2rem;
}

.user-menu {
  padding-right: 1rem;
}

.app-logo {
  font-size: larger;
  font-weight: bolder;
  color: #FFF;
}

.logo-spliter {
  color: #FFF;
}

.user-info {
  font-size: medium;
  color: #FFF;
}

.companyName {
  line-height: 64px;
  color: #ccc;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  margin-right: 20px;
}
