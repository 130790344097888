.yearly-coverflex{
    font-weight: bolder;
    color: #BFBFBF;
}

.margin-0 {
    margin: 0;
}

.margin-right-small{
    margin-right: 1.5rem;
}

.margin-bottom-0{
    margin-bottom: 0;
}