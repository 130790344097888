body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

#root{
  position: fixed;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pageTitle {
  margin: 0 auto 24px 0;
  margin-bottom: 24px !important;
}

.ant-pagination {
  text-align: right;
  margin: 20px 0;
}

.IconTable {
  color: #1677FF;
  font-size: 1.5rem;
}

.read-only{
  color: black;
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.read-only .ant-input{
  color: black !important;
  background-color: #f5f5f5 !important;
  cursor: not-allowed;
}

.read-only .ant-input-group-addon{
  color: black !important;
  background-color: #f5f5f5 !important;
}

.fullWidth {
  width: 100%;
}

.paddingTop2Rem{
  padding-top: 2rem;
}

.paddingBottom1Rem{
  padding-bottom: 1rem;
}

.paddingBottom2Rem {
  padding-bottom: 2rem;
}

.paddingTop1Rem {
  padding-top: 1rem;
}

.paddingRightHalfRem{
  padding-right: 0.5rem;
}

.paddingLeft1Rem{
  padding-left: 1rem;
}

.marginRight1Rem{
  margin-right: 1rem;
}

.alignLeft{
  text-align: left;
}

.alignRight{
  text-align: right;
}

.alignCenter{
  text-align: center;
}

.marginZero {
  margin: 0;
}

.marginBottom1Rem{
  margin-bottom: 1rem;
}

.marginTop05Rem{
  margin-top: 0.5rem;
}

.marginTop1Rem{
  margin-top: 1rem;
}

.marginLeft1Rem{
  margin-left: 1rem;
}

.selected-row {
  border: 0;
  background: #E6F7FF;
  ;
}

tr.selected-row:hover > td {
  background: #E6F7FF !important;
}
