.logout-form {
    width: 300px;
    align-content: center;
    margin: auto !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

}