.footer {
    text-align: center;
    padding: 1rem 0 2.2rem 0;
}

.created-with {
    padding-left: 0.8rem;
}

.dot {
    padding-left: 0.8rem;
}

.hearth {
    padding-left: 0.8rem;
}