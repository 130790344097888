.formWrapper {
    max-width: 1200px;
    margin: 0 auto auto auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 310px);
}

.loader {
    font-size: 32px;
    align-self: center; 
    margin: auto auto;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.margin0 {
    margin: 0;
}

.marginBottom0 {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .formWrapper {
        max-width: 100%;
        margin: 0 auto auto auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - 310px);
    }
}

