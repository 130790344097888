.ant-table-footer {
    text-align: right;
}

.date {
    font-size: 1.8em;
}

.total,
.subtotal {
    margin-top: 36px;
    background-color: #fafafa;
    font-size: 1.1em;
}

.smaller {
    font-size: 0.9em;
}

.euro {
    font-size: 0.85em;
}

td > h3,
td > h4 {
    margin: 0 !important;
}