.rowSpace {
    padding-bottom: 1.3em;
}

.color-red {
    color: #ff0000;
}

.status-ok {
    color: #27ff0e;
}

.btn-disabled {
    color: #7C7C7C;
    cursor: not-allowed;
}

.row-disabled {
    color: #7C7C7C;
}